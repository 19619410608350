export enum OrderStep {
  SHIPPING = 'shipping',
  MONITORING = 'monitoring',
  PAYMENT = 'payment',
  REVIEW = 'review',
  COMPLETE = 'complete',
}

export enum OrderFinancingStatus {
  REQUEST_ONBOARDING = 'RequestOnboarding',
  OPENED = 'Opened',
  APPROVED = 'Approved',
  DECLINED = 'Declined',
  ACTIVE = 'Active',
}

export type OrderAddressKind = 'ship' | 'bill' | 'monitor';

export type OrderAddress = {
  address1: string;
  cleanStreetAddress?: string;
  originalAddress1?: string;
  address2?: string;
  alternativePhone?: string;
  city: string;
  company?: string;
  county?: string;
  firstName?: string;
  kind: OrderAddressKind;
  lastName?: string;
  phone?: string;
  stateName: string;
  zipCode: string;
};

export type OrderCreditCard = {
  number: string;
  name: string;
  expirationDate: string;
  cvc: string;
  type: string;
  authorizedAmount: number;
  useForMonitoring: boolean;
};

export type OrderTokens = {
  authorizedPartnerToken: string;
  customerToken: string;
  salesRepToken: string;
};

export type OrderShipping = {
  name?: string;
  netsuiteId: number;
  price: number;
  sku?: string;
};

export type OrderDiscount = {
  amount: number;
  image?: string;
  name?: string;
  netsuiteId: number;
  sku?: string;
};

export type OrderItem = {
  discountPerUnit?: number;
  image: string;
  name: string;
  netsuiteId: number;
  price: number;
  quantity: number;
  sku: string;
};

export type OrderPackage = {
  discountPerUnit: number;
  items: OrderItem[];
  image?: string;
  name: string;
  netsuiteId: number;
  price: number;
  services: OrderItem[];
  sku: string;
};

export enum QualifiedTier {
  NONE = 'None',
  QUALIFIED = 'Qualified',
}

export type OrderSalesRep = {
  firstName: string;
  lastName: string;
  netsuiteId: number;
};

export type CheckoutUserRole = 'salesRep' | 'customer' | 'authorizedPartner' | 'fieldSalesPartner';

export type OrderMonitoringInformation = {
  firstName: string;
  lastName: string;
  primaryPhone: string;
  secondaryPhone: string;
  payChecks?: number;
};

export type CustomerInformation = {
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  alternativePhone?: string;
  netsuiteCustomerId?: number;
  ismasprepopulated: boolean;
  leadSource?: string;
  supportPhone?: string;
  prospectNumber?: number;
  codeword?: string;
  vId?: string;
  gclId?: string;
};

export type Order = {
  additionalAmount?:string,
  additionalCreditCard?: OrderCreditCard | null;
  additionalNonce?:string,
  addresses?: OrderAddress[];
  authToken: string;
  checkoutAs: CheckoutUserRole;
  contractUrl?: string;
  cpqQuoteId?: string;
  creditCard?: OrderCreditCard | null;
  customer: CustomerInformation;
  customerPreferredActivateDate?: Date;
  customerSsn?: string;
  defaultAmount?:string,
  defaultNonce?:string,
  discounts?: OrderDiscount[];
  discountTotal: number;
  featureFlags?: string[];
  financingMonthlyTotal: number;
  financingMonths: number;
  financingReferenceNumber?: string;
  financingStatus?: OrderFinancingStatus;
  financingUrl?: string;
  id?: string;
  isEligibleForAffirmFinancing: boolean;
  isEligibleForPayCheck: boolean;
  isEligibleForSplitPayment: boolean;
  isEligibleForSubsidizing: boolean;
  isEligibleForSunnovaFinancing: boolean;
  isFinanced: boolean;
  isPayCheck?: boolean;
  isSubsidized: boolean;
  isSunnovaFinanced?: boolean;
  isSplitPayment?: boolean;
  itemAddons?: OrderItem[];
  memo?: string;
  monitoringInformation?: OrderMonitoringInformation;
  netsuiteId?: number;
  netsuiteQuoteId?: string;
  package?: OrderPackage;
  postSaleManagerUrl?: string;
  qualifiedTier?: QualifiedTier;
  salesRep?: OrderSalesRep;
  salesTax?: number;
  serviceAddons?: OrderItem[];
  shipping: OrderShipping;
  step?: OrderStep;
  subsidizingMonthlyTotal: number;
  subtotal: number;
  total: number;
  store: string;
};

