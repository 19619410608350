import axios from 'axios';
import config from '@/config';
import { Order, OrderAddress, StepName, CodewordEncryptRequest, CodewordUpdateRequest, CodewordResponse, isValidCodewordRequest } from '@/interfaces';
import newAddress from '@/constants/new-address';
import moment from 'moment';

const checkoutApi = axios.create({
  baseURL: config.checkoutApiUrl,
});

export default class CheckoutApi {
  async fetchOrder(id: string, token: string) {
    const { data } = await checkoutApi.get(`/orders/${id}`, {
      headers: {
        'auth-token': token,
      },
    });
    if (!data.addresses.find((a: OrderAddress) => a.kind === 'ship')) {
      data.addresses = [...(data.addresses ?? []), newAddress('ship')];
    }
    const activateDate = data.customerPreferredActivateDate;
    if (activateDate) {
      const timezoneDatetime = new Date(activateDate).toLocaleString("en-US", { timeZone: 'America/Los_Angeles', hour12: false });
      data.customerPreferredActivateDate = moment(timezoneDatetime, 'MM-DD-YYYY HH:mm:ss').toDate();
    }

    return data;
  }

  async completeStep(order: Order, step: StepName, payload: any) {
    const { data } = await checkoutApi.post(`/orders/${order.id}/steps/${step}/complete`, payload, {
      headers: {
        'auth-token': order.authToken,
      },
    });

    return data;
  }

  async resetOrderStep(orderId: string, authToken: string) {
    const { data } = await checkoutApi.post(
      `/orders/${orderId}/steps/reset`,
      {},
      {
        headers: {
          'auth-token': authToken,
        },
      },
    );

    return data;
  }

  async validateAddress(address: OrderAddress) {
    try {
      const { data } = await checkoutApi.post('/addresses/validate', {
        ...address,
      });
      return data;
    } catch (e) {
      console.error('Location validation failed: ', e);
      return {
        success: false,
      };
    }
  }

  async addressesByZipCode(zipCode: string) {
    const { data } = await checkoutApi.get(`/addresses/by_zip_code/${zipCode}`);
    return data;
  }

  async fetchPaymentToken(order: Order): Promise<any> {
    const response = await checkoutApi.get(`/orders/${order.id}/payment/client-token`, {
      headers: {
        'auth-token': order.authToken,
      },
    });

    return response.data;
  }

  async beginPaymentTransaction(order: Order, data: any): Promise<any> {
    const response = await checkoutApi.post(`/orders/${order.id}/payment/begin-transaction`, data, {
      headers: {
        'auth-token': order.authToken,
      },
    });
    return response.data;
  }

  async isValidCodeword(input: isValidCodewordRequest): Promise<boolean> {
    try {
      const { data } = await checkoutApi.post(`/codeword/validate`, input);
      return data;
    } catch (e) {
      console.error('Codeword validation failed: ', e);
      return false;
    }
  }

  async encryptCodeword(payload: CodewordEncryptRequest): Promise<CodewordResponse> {
    const response: CodewordResponse = {success: false, data: null};
    try {
      const { data } = await checkoutApi.post('/codeword/encrypt', payload);
      response.success = true;
      response.data = data;
    } catch (e) {
      console.error('Codeword encryption failed: ', e);
    }
    return response
  }

  async updateCodeword(payload: CodewordUpdateRequest): Promise<CodewordResponse> {
    const response: CodewordResponse = {success: false, data: null};
    try {
      const { data } = await checkoutApi.put('/codeword', payload);
      response.success = true;
      response.data = data;
    } catch (e) {
      console.error('Codeword update failed: ', e);
    }
    return response;
  }
}
